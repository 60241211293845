
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import ApexChart from "vue-apexcharts";

@Component({
  components: { ApexChart },
})
export default class DeviceOverviewCard extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly title_number: string;
  @Prop({default: ''}) subTitle: string;
  @Prop() readonly card_icon: string;
  @Prop() readonly card_icon_color: string;
  @Prop() readonly text_font_size: string;
  @Prop({default: 'bold'}) readonly text_font_weight: string;
  @Prop({default: ''}) readonly unit: string;
  @Prop({default: false}) readonly state: boolean;
  @Prop({default: 3}) readonly card_size: string;

  get switchState() {
    return parseInt(this.title_number) ? 'ON' : 'OFF'
  }

  get unitSymbol() {
    switch (this.unit) {
      case 'celsius':
        return '&deg; C'
      case 'percentage':
        return '%'
      case 'bar':
        return 'bar'
      case 'pm':
        return 'µg/m³'
      case 'ppm':
        return 'ppm'
      case 'kw':
        return 'kW'
      case 'kwh':
        return 'kWh'
      case 'watt':
        return 'W'
      case 'wmin':
        return 'Wmin'
      case 'voltage':
        return 'V'
      case 'euro':
        return '€'
      case 'usd':
        return '$'
      case 'kg':
        return 'kg'
      case 'lux':
        return 'lx'
      default:
        return ''
    }
  }
}



