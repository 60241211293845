import { render, staticRenderFns } from "./IAQChartView.vue?vue&type=template&id=19179773&scoped=true&class=fill-height"
import script from "./IAQChartView.vue?vue&type=script&lang=ts"
export * from "./IAQChartView.vue?vue&type=script&lang=ts"
import style0 from "./IAQChartView.vue?vue&type=style&index=0&id=19179773&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19179773",
  null
  
)

export default component.exports